.svg-image {
	height: 100%;
	width: auto;
}

.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;

	.embed-responsive-item {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	&.embed-responsive-16by9 {
		padding-bottom: 56.25%;
	}
}

.table-ellipsis {
	overflow-x: hidden;
	color: transparent;
	max-height: 1.2em;
	position: relative;

	&:after {
		content: attr(title);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		color: $text;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow-x: hidden;
	}
}

.no-wrap {
	white-space: nowrap;
}
.yes-wrap {
	white-space: initial;
}

.pad-025rem {
	padding: 0.25rem;
}
.pad-05rem {
	padding: 0.5rem;
}
.pad-1rem {
	padding: 1rem;
}
.pad-top-1rem {
	padding-top: 1rem;
}
.pad-bottom-1rem {
	padding-bottom: 1rem;
}
.pad-right-1rem {
	padding-right: 1rem;
}
.pad-left-1rem {
	padding-left: 1rem;
}
.pad-bottom-2rem {
	padding-bottom: 2rem;
}
.pad-top-3rem {
	padding-top: 3rem;
}
.pad-bottom-3rem {
	padding-bottom: 3rem;
}
.pad-bottom-0rem {
	padding-bottom: 0rem;
}

.margin-top-1rem {
	margin-top: 1rem !important;
}
.margin-bottom-1rem {
	margin-bottom: 1rem !important;
}
.margin-right-1rem {
	margin-right: 1rem !important;
}
.margin-left-1rem {
	margin-left: 1rem !important;
}
