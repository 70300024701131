@media print {
	@page {
		// fixes Playwright not setting margins
		margin: 15mm 15mm 15mm 15mm !important;
	}

	.print-none {
		display: none !important;
	}
	.main-menu,
	.agentur-filter-container,
	.burger,
	.modal-footer {
		display: none;
	}

	html,
	body,
	#root,
	.app-container {
		height: 100%;
		font-size: 12px;
	}
	button {
		font-size: 12px;
	}

	.app-container,
	.app-content {
		overflow: visible !important;
	}

	.tag {
		color: $black;
		background: transparent;
	}

	.document-ap {
		border: none !important;
	}

	.document-form {
		&:not(.is-jpg) {
			.html-content {
				border: 0;
			}
		}

		.document-actions,
		.document-uploads,
		.document-tag,
		.document-changes,
		.document-agt,
		.title {
			display: none;
		}
		.document-content {
			.html-content {
				border-radius: $radius;
			}
		}

		.document-intro,
		.ziele-intro {
			color: $text !important;
		}

		.fokusthemen {
			page-break-inside: avoid;

			table {
				td {
					white-space: break-spaces;
				}
				td:last-of-type {
					width: 100%;
					padding-left: 2rem;
				}
			}
		}

		.ziele-list {
			.ziele-list-item {
				display: block;
				background: transparent;

				&:before {
					content: "\25CF";
					padding-right: 0.5rem;
				}
			}
		}
	}

	table {
		tr,
		td,
		th {
			page-break-inside: avoid;
			border: 0 !important;
			box-shadow: none !important;
			font-size: 12px;
		}

		tr {
			border-bottom: 2px solid $border !important;
		}
	}

	.table-container {
		width: 100%;
		height: auto;
		max-height: auto;
		overflow: visible;
	}

	.auth-form {
		opacity: 0;
	}
	.app-container.is-auth {
		background: white;
	}
	.zeitraum-select {
		display: none;

		& + .table-container {
			border-top: 0;
		}
	}

	.panel {
		width: 100%;

		.body,
		.panel-body {
			overflow: visible !important;
		}
	}
	.side-panel {
		width: 100%;
		position: static;
		box-shadow: none;
		padding: 1rem 0;
		overflow: initial;
		display: block;

		& > .header,
		.panel-header {
			display: none;
		}

		.side-panel {
			width: 100%;
			padding: 0;

			& + .table-container {
				display: none;
			}
		}
	}

	.table-edit-header,
	.table-edit-footer {
		display: none;
	}

	.button:not(.print-allow) {
		display: none;
	}

	.fitness-detail-nav {
		display: none;
	}

	.page-print-loaded {
		display: none;
	}
}

.print-header {
	@media screen {
		display: none;
	}

	.allianz-logo {
		height: 30px;

		svg {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.page-break-avoid {
	page-break-inside: avoid;
}
.page-break-before {
	page-break-before: always;
}
.page-break-after {
	page-break-after: always;
}

// new panel print styles
@media print {
	.agt-list-container {
		display: none;
	}

	.panel:last-of-type {
		//border: 5px solid red !important;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: auto;
		z-index: 10000;

		& > .header {
			display: none;
		}

		.panel-body {
			position: static;
		}
	}

	.navbar,
	.tree-nodes-container {
		display: none;
	}
}

.print-page-loaded {
	position: absolute;
	top: 0;
	z-index: -1;
}
